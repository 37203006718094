




























































































































































































































































































































    .el-information{
        .el-col{
            margin-bottom:15px ;
        }
    }
    .el-icon-s-fold,.el-icon-s-unfold{
        font-size: 35px;
        margin:22px ;
        color: #999;
    }
    .mr15 {
      margin-right: 15px;
    }
    
    .avatar {
      display: flex;
    height: 50px;
      .el-avatar {
        margin-right: 20px;
      }
    }
    .userDetail{
      font-weight: bold;
    }
    .herParent {
      display: flex;
      align-items: center
    }
    
    .CheckInTimeTitle {
      
      font-size: 12px;
      color: #606266;
      margin-right: 5px;
      width: 60px;
    }
  .v-distpicker ::v-deep select {
  height: 32px !important;
  padding-top: 0.4rem;
  font-size: 14px;
}
    